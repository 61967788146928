import React from 'react'
import { Link } from 'gatsby'
// custom
import Layout from 'src/components/view/layout'

function Home() {
  return (
    <Layout>
      <h1 className="text-center pt-5 text-white">Dashboards</h1>

      <ul className=" mt-5">
        <li>
          <Link to={`/app/dashboard/example-url`} style={{ fontSize: `2rem` }}>
            example-url - Regular
          </Link>
        </li>
        <li>
          <Link
            to={`/app/dashboard/example-url?ticket=3`}
            style={{ fontSize: `2rem` }}
          >
            example-url - Custom ticket
          </Link>
        </li>
      </ul>
    </Layout>
  )
}

export default Home
